









































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class PopoverInput extends Vue {
    // props
    @Prop() value!: string
    @Prop({ default: 'number' }) type!: 'text' | 'number'
    @Prop({ default: '250' }) width!: number | string
    @Prop({ default: false }) disabled!: boolean
    @Prop() tips!: string
    visible = false
    inputValue = ''
    @Watch('value', { immediate: true })
    valueChange(val: string) {
        this.inputValue = val
    }
    handleConfirm() {
        this.close()
        this.$emit('confirm', this.inputValue)
    }
    handleOpen() {
        if(this.disabled) return
        this.visible = true
    }
    close() {
        this.visible = false
    }
}
