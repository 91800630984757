
















































import config from '@/config'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import UploadList from 'element-ui/packages/upload/src/upload-list.vue'

@Component({
    components: {
        UploadList,
    },
})
export default class Upload extends Vue {
    $refs!: { upload: any }
    @Prop({ default: 10 }) limit!: number
    @Prop({ default: true }) multiple!: boolean
    @Prop({ default: () => {} }) data!: any
    @Prop({ default: 'image' }) type!: 'image' | 'video'
    @Prop({default: false}) showProgress!: boolean
    visible = false
    action = `${config.baseURL}/platformapi/upload/${this.type}`
    fileList: any[] = []
    version = config.version
    handleProgress(event: any, file: any, fileList: any[]) {
        this.visible = true
        this.fileList = fileList
    }
    handleSuccess(response: any, file: any, fileList: any[]) {
        let allSuccess = fileList.every((item) => item.status == 'success')
        if (allSuccess) {
            this.$refs.upload.clearFiles()
            this.visible = false
            this.$emit('change')
        }
    }
    handleError(err: any, file: any) {
        this.$message.error(`${file.name}文件上传失败`)
        this.$refs.upload.abort()
        this.visible = false
        this.$emit('change')
        this.$emit('error')
    }
    handleExceed() {
        this.$message.error("超出上传上限，请重新上传")
    }
    handleClose() {
        this.$refs.upload.abort()
        this.$refs.upload.clearFiles()
        this.visible = false
    }
}
